import { Duration } from "./types";

export const getDurationBetweenDates = (
  startDateISO: string,
  endDateISO: string
): Duration => {
  const startDate = new Date(startDateISO).getTime();
  const endDate = new Date(endDateISO).getTime();
  const durationInMilliseconds = Math.abs(endDate - startDate);

  const milliseconds = durationInMilliseconds % 1000;
  const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
  const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)) % 24);
  const days = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));

  return {
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};
