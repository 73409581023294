import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "../pages/Home";
import Game from "../pages/Game";
import { useEffect } from "react";
import { removeKey } from "../utils/localstorage.util";
import CanAccessGame from "../guards/canAccessGame";
import Dashboard from "../pages/Dashboard";
import { DASHBOARD_PATH, GAME_PATH } from "../utils/constants";
import Intro from "../pages/Intro";

const Routers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    removeKey("USER");
    if (location.pathname !== DASHBOARD_PATH) navigate("/intro");
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/intro" element={<Intro />} />
      <Route path={DASHBOARD_PATH} element={<Dashboard />} />
      <Route element={<CanAccessGame />}>
        <Route path={GAME_PATH} element={<Game />} />
      </Route>

      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};

export default Routers;
