import React, { useCallback, useEffect, useRef, useState } from "react";

export default function useTimer(): [number, string, Function] {
  const [timer, setTimer] = useState(0);
  const timerRef = useRef<any>(null);
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimer((time) => time + 1);
    }, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const stopTimer = useCallback(() => {
    clearInterval(timerRef.current);
  }, [timerRef.current]);
  return [
    timer,
    `${Math.floor((timer / 60) % 60)
      .toString()
      .padStart(2, "0")}:${Math.floor(timer % 60)
      .toString()
      .padStart(2, "0")}
      `,
    stopTimer,
  ];
}
