import { ANSWER_SCORE, COLLECTION_NAME } from "./constants";
import { db } from "./firebase-config";
import "firebase/database";
import { Questions, User } from "./types";
import { getDurationBetweenDates } from ".";

export const addUser = async (name: string) => {
  const userId = db.collection(COLLECTION_NAME).doc().id;
  const userContent = {
    name,
    create_at: new Date().toISOString(),
    lastAnswer_at: new Date().toISOString(),
    start_at: new Date().toISOString(),
    correctAnswers: [],
    falseAnswers: [],
  };
  await db.collection(COLLECTION_NAME).doc(userId).set(userContent);
  return { id: userId, ...userContent };
};

export const updateLastAnswer = async (userId: string) => {
  db.collection(COLLECTION_NAME)
    .doc(userId)
    .update({ lastAnswer_at: new Date().toISOString() });
};

export const updateStartAt = async (userId: string) => {
  db.collection(COLLECTION_NAME)
    .doc(userId)
    .update({ start_at: new Date().toISOString() });
};

export const submitAnswer = async (
  userId: string,
  questions: Questions[],
  questionId: string,
  answer: string
) => {
  const user = await getUserById(userId);
  let correctAnswers = user.correctAnswers || [];
  let falseAnswers = user.falseAnswers || [];

  const question = questions.find((q) => q.id === questionId);
  if (
    answer === question?.correctAnswer &&
    !correctAnswers.find((ans) => ans.questionId === questionId)
  ) {
    correctAnswers.push({ questionId, value: answer });
  } else if (
    answer !== question?.correctAnswer &&
    !falseAnswers.find((ans) => ans.questionId === questionId)
  ) {
    falseAnswers.push({ questionId, value: answer });
  }
  await db.collection(COLLECTION_NAME).doc(userId).update({
    correctAnswers,
    falseAnswers,
  });
  await updateLastAnswer(userId);

  return {
    score: correctAnswers.length * ANSWER_SCORE,
    correctAnswer: question?.correctAnswer,
    answer,
  };
};

export const getUserResult = async (userId: string) => {
  const userRef = db.collection(COLLECTION_NAME).doc(userId).get();
  const userData = (await userRef).data() as User;

  if (!userData) return {};
  return {
    duration: getDurationBetweenDates(
      userData?.start_at,
      userData?.lastAnswer_at
    ),
    score: userData.correctAnswers.length * ANSWER_SCORE,
  };
};

export const getQuestions = async (): Promise<Questions[]> => {
  const questionsDocs = (await db.collection("Questions").get()).docs;
  const questions = questionsDocs.map((doc) => ({
    id: doc.id,
    value: doc.data().value,
    isAnswer: false,
    answers: doc.data().answers,
    correctAnswer: doc.data().correctAnswer,
  }));
  return questions;
};

export const getUserById = async (userId: string): Promise<User> => {
  const userRef = db.collection(COLLECTION_NAME).doc(userId).get();
  const userData = (await userRef).data() as User;

  return userData;
};

export const getAllUsers = async (): Promise<User[]> => {
  const usersDocs = (await db.collection(COLLECTION_NAME).get()).docs;
  const users = usersDocs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      name: data.name,
      create_at: data.create_at,
      start_at: data.start_at,
      lastAnswer_at: data.lastAnswer_at,
      correctAnswers: data.correctAnswers,
      falseAnswers: data.falseAnswers,
      score: data.correctAnswers.length * ANSWER_SCORE,
    };
  });
  return users;
};
