import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { INTRO_TEXT } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import Nav from "../components/Nav";
import OpenVideo from "../assets/Open.mp4";
import CloseVideo from "../assets/Close.mp4";
import InhaleVideo from "../assets/Inhale.mp4";
import GameWinSound from "../assets/Game Win Horns .wav";
import ApprovalCode from "../components/ApprovalCode";
export default function Intro() {
  const navigate = useNavigate();
  const [activeVideo, setActiveVideo] = useState("open");
  const [isFinish, setIsFinish] = useState(false);
  const OpenVideoRef = useRef<any>(null);
  const InhaleVideoRef = useRef<any>(null);
  const CloseVideoRef = useRef<any>(null);
  const PlayOpenVideo = useCallback(() => {
    if (activeVideo === "open") {
      OpenVideoRef.current?.play();
    }
  }, [OpenVideoRef.current, activeVideo]);
  const PlayInhaleVideo = useCallback(() => {
    if (activeVideo === "inhale") {
      InhaleVideoRef.current?.play();
    }
  }, [InhaleVideoRef.current, activeVideo]);
  const PlayCloseVideo = useCallback(() => {
    if (activeVideo === "close") {
      CloseVideoRef.current?.play();
    }
  }, [CloseVideoRef.current, activeVideo]);
  const incomingStep = useMemo(() => {
    const stepIndex =
      activeVideo === "open"
        ? "First"
        : activeVideo === "inhale"
        ? "Second"
        : "Third";
    return `${stepIndex} Step`;
  }, [activeVideo]);

  useEffect(() => {
    let timer: any;
    if (isFinish) {
      new Audio(GameWinSound).play();
      timer = setTimeout(() => {
        navigate("/");
      }, 2500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isFinish]);
  return (
    <>
      <Nav hideGskLogo={true} />
      <article className="intro-container">
        <section className="left-side">
          <video
            ref={OpenVideoRef}
            className={`open-video ${activeVideo === "open" ? "active" : ""}`}
            onEnded={() => {
              setActiveVideo("inhale");
              if (OpenVideoRef.current) OpenVideoRef.current.currentTime = 0;
            }}
          >
            <source src={OpenVideo} type="video/mp4" />
          </video>
          <video
            ref={InhaleVideoRef}
            className={`inhale-video ${
              activeVideo === "inhale" ? "active" : ""
            }`}
            onEnded={() => {
              setActiveVideo("close");
              if (InhaleVideoRef.current)
                InhaleVideoRef.current.currentTime = 0;
            }}
          >
            <source src={InhaleVideo} type="video/mp4" />
          </video>
          <video
            ref={CloseVideoRef}
            className={`close-video ${activeVideo === "close" ? "active" : ""}`}
            onEnded={() => {
              setActiveVideo("open");
              if (CloseVideoRef.current) CloseVideoRef.current.currentTime = 0;

              setIsFinish(true);
            }}
          >
            <source src={CloseVideo} type="video/mp4" />
          </video>
        </section>
        <section className="right-side">
          <h4>{INTRO_TEXT}</h4>
          <p className="incoming-step">{incomingStep}</p>
          <button onClick={PlayCloseVideo}>Close</button>
          <button onClick={PlayOpenVideo}>Open/ Slide</button>
          <button onClick={PlayInhaleVideo}>Inhale</button>
        </section>
        {isFinish && (
          <section className="overlay">
            <p>Now Let's See</p>
            <h4>How Well Do You Know Seretide?</h4>
          </section>
        )}
      </article>
      <ApprovalCode />
    </>
  );
}
