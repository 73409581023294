import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getJsonValue } from "../utils/localstorage.util";
import useUserResult from "../hooks/useUserResult";
import Nav from "./Nav";
import ApprovalCode from "./ApprovalCode";
import QRCode from "../assets/Seretide Discus.png";
import { DISCLAIMERS } from "../utils/constants";
export default function GameResult({}) {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const result = useUserResult({
    userId: getJsonValue("USER").id,
  });
  const restartGame = useCallback(() => {
    localStorage.clear();
    navigate("/intro");
  }, []);

  useEffect(() => {
    let timer: any;

    timer = setTimeout(() => {
      setStep((s) => 2);
    }, 2500);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <article className="result-container">
        {step == 1 ? (
          <section className="result">
            <h2>Good Job</h2>
            {result?.duration && (
              <div>
                <p className="title">TIME</p>
                <p className="value ">
                  {result.duration.minutes?.toString().padStart(2, "0")}:
                  {result.duration.seconds?.toString().padStart(2, "0")}
                </p>
              </div>
            )}
            {result?.score && (
              <div>
                <p className="title">SCORE</p>
                <p className="value ">{result.score}</p>
              </div>
            )}
          </section>
        ) : (
          <section className="qr-container">
            <div>
              <img src={QRCode} />
            </div>
            <p>Seretide Diskus Prescribing Information</p>
            <button
              onClick={() => {
                restartGame();
              }}
            >
              Restart
            </button>
          </section>
        )}
        <footer>
          {DISCLAIMERS.map((value) => (
            <p key={value}>{value}</p>
          ))}
          <Nav hideGskLogo={true} flexEnd={true} />
        </footer>
      </article>

      <ApprovalCode />
    </>
  );
}
