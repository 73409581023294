import React, { useMemo } from "react";
import { User } from "../utils/types";
import useQuestions from "../hooks/useQuestions";

export default function UserAnswers({
  user,
  closeModal,
}: {
  user: User | null;
  closeModal: Function;
}) {
  const [questions] = useQuestions();
  const userAnswers = useMemo(() => {
    if (!user) return [];
    const answers = [
      ...user.correctAnswers.map((ans) => ({ ...ans, result: true })),
      ...user.falseAnswers.map((ans) => ({ ...ans, result: false })),
    ];
    return answers.map((ans) => (
      <tr key={ans.questionId}>
        <td>{questions.find((q) => q.id === ans.questionId)?.value}</td>
        <td>{ans.value}</td>
        <td>
          {ans.result ? (
            <i className="fa-solid fa-check"></i>
          ) : (
            <i className="fa-solid fa-xmark"></i>
          )}
        </td>
      </tr>
    ));
  }, [user, questions]);
  return (
    <article className="user-modal-container">
      <section
        className="main-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>{userAnswers}</tbody>
        </table>
        <button
          onClick={() => {
            closeModal();
          }}
        >
          Close
        </button>
      </section>
    </article>
  );
}
