import React from "react";
import SyncLoader from "react-spinners/SyncLoader";

export default function Loader({message}:{message:string}) {
  return (
    <section className="loader">
      <p>{message}</p>
      <SyncLoader color="#5f308e" size={25} />
    </section>
  );
}
