import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDbfRhDJ1iNnSiB_XCMlNOWafUH9ZTOLN0",
  authDomain: "seretide-game.firebaseapp.com",
  projectId: "seretide-game",
  storageBucket: "seretide-game.appspot.com",
  messagingSenderId: "1088991080942",
  appId: "1:1088991080942:web:e5711712208b8ce641deff",
  measurementId: "G-2JJX8SWQCD",
};

let firebaseApp;

if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
} else {
  firebaseApp = firebase.app();
}

export const db = firebase.firestore();

export default firebaseApp;
