export const setKey = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const setJSONKey = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getValue = (key: string) => {
  return localStorage.getItem(key);
};

export const getJsonValue = (key: string) => {
  const value = getValue(key);
  return value ? JSON.parse(value) : undefined;
};

export const removeKey = (key: string) => {
  return localStorage.removeItem(key);
};
