import React, { useCallback, useEffect, useState } from "react";
import { Duration } from "../utils/types";
import { getUserResult } from "../utils/firebase.actions";

export default function useUserResult({ userId }: { userId: string }) {
  const [result, setResult] = useState<{
    duration?: Duration;
    score?: number;
  }>();
  const getGameTime = useCallback(async () => {
    const { duration, score } = await getUserResult(userId);
    setResult({ duration, score });
  }, [result]);

  useEffect(() => {
    getGameTime();
  }, []);

  return result;
}
