import React, { useEffect, useState } from "react";
import { Questions } from "../utils/types";
import { getQuestions } from "../utils/firebase.actions";

export default function useQuestions():[Questions[], React.Dispatch<React.SetStateAction<Questions[]>>] {
  const [questions, setQuestions] = useState<Questions[]>([]);
  useEffect(() => {
    getQuestions().then((data) => {
      setQuestions(data);
    });
  }, []);
  return [questions, setQuestions];
}
