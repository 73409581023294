import React, { useCallback, useState } from "react";
import { addUser } from "../utils/firebase.actions";
import { setJSONKey } from "../utils/localstorage.util";
import { useNavigate } from "react-router-dom";
import Nav from "../components/Nav";

export default function Home() {
  const navigate = useNavigate();
  const [teamName, setTeamName] = useState("");
  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const user = await addUser(teamName);
      setJSONKey("USER", user);
      navigate("/game", { replace: true });
    },
    [teamName]
  );

  return (
    <>
      <Nav />
      <section className="home-container">
        <form onSubmit={handleSubmit}>
          <h3>Enter Your Name </h3>
          <input
            type="text"
            value={teamName}
            onChange={(e) => {
              setTeamName(e.target.value);
            }}
            required
          />
          <button>Start</button>
        </form>
      </section>
    </>
  );
}
