export const COLLECTION_NAME = "USERS";
export const DASHBOARD_PATH = "/dashboard";
export const GAME_PATH = "/game";
export const ANSWER_SCORE = 5;
export const INTRO_TEXT = "Arrange the steps in their correct order";
export const QUESTION_REFERENCES: { [key: string]: any } = {
  "1": "1.Salmeterol-Fluticasone propionate;GDS;37;2020;1-42",
  "2": "2.Singh, D., Oosterholt, S., Pavord, I. et al.Understanding the Clinical Implications of Individual Patient Characteristics and Treatment Choice on the Risk of Exacerbation in Asthma Patients with Moderate-Severe Symptoms. Adv Ther 40, 4606-4625 (2023). https://doi.org/10.1007/s12325-023-02590-2",
};

export const DISCLAIMERS = [
  `For more information, please refer to the prescribing information or contact GSK: P.O Box 55850, Jeddah, 21544, Kingdom of Saudi Arabia. Telephone: +966 12 653 6666 or via gcc.
medinfo@gsk.com`,
  `To report Adverse Event/s associated with the use of GSK product/s, please contact us via 
  saudi.safety@gsk.com`,
  `To report the Quality related product complaint/s associated with the use of GSK product/s, please contact us via
ksa.productqualitycomplaint@gsk.com`,
  `Trademarks are owned by or licensed to the GSK group of companies.

©2024 GSK group of companies or its licensor.

GlaxoSmithKline Limited, Registered in Saudi Arabia.

P.O. Box 55850, Jeddah 21544, Saudi Arabia`,
];
