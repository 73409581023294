import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { getJsonValue } from "../utils/localstorage.util";

const CanAccessGame = () => {
  const navigate = useNavigate();
  const user = getJsonValue("USER");
  useEffect(() => {
    if (!user) navigate("/");
  }, [user]);
  return <Outlet />;
};

export default CanAccessGame;
