import React from "react";
import SeretideLogo from "../assets/seretide-logo.png";
import GskLogo from "../assets/gsk-logo.png";
export default function Nav({
  hideGskLogo,
  flexEnd,
}: {
  hideGskLogo?: boolean;
  flexEnd?: boolean;
}) {
  return (
    <nav className={`nav-component ${flexEnd ? "flex-end" : ""}`}>
      {!hideGskLogo && <img src={GskLogo} alt="gsk" />}
      <img src={SeretideLogo} alt="seretide" />
    </nav>
  );
}
