import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  getQuestions,
  submitAnswer,
  updateLastAnswer,
  updateStartAt,
} from "../utils/firebase.actions";
import { Questions } from "../utils/types";
import firebase from "firebase";
import { getJsonValue } from "../utils/localstorage.util";
import CorrectAnswerImage from "../assets/true-answer.png";
import WrongAnswerImage from "../assets/false-answer.png";
import GameResult from "../components/GameResult";
import Loader from "../components/Loader";
import useQuestions from "../hooks/useQuestions";
import useTimer from "../hooks/useTimer";
import QuestionFooter from "../assets/question-footer.png";
import SussesSound from "../assets/success.mp3";
import WrongSound from "../assets/wrong.mp3";
import { QUESTION_REFERENCES } from "../utils/constants";
export default function Game() {
  const user = getJsonValue("USER");
  const [questions, setQuestions] = useQuestions();
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState({
    correctAnswer: "",
    userAnswer: "",
  });
  const [timer, timerValue, stopTimer] = useTimer();
  const [currentQuestion, setCurrentQuestion] = useState<Questions>();
  const [isGameEnd, setGameEnd] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const getRandomQuestion = useCallback(() => {
    const unAnsweredQuestions = questions.filter((q) => !q.isAnswer);
    if (questions.length && !unAnsweredQuestions.length) {
      setGameEnd(true);
      stopTimer();
    }
    setCurrentQuestion(
      unAnsweredQuestions[
        Math.floor(Math.random() * unAnsweredQuestions.length)
      ]
    );
  }, [questions, currentQuestion]);

  const answerAction = useCallback(
    async (answer: string) => {
      if (feedback.correctAnswer || feedback.userAnswer) return;
      setLoading(true);
      const returnedFeedback = await submitAnswer(
        user.id,
        questions,
        currentQuestion!.id,
        answer
      );
      setScore(returnedFeedback.score);
      setLoading(false);
      setFeedback({
        correctAnswer: returnedFeedback.correctAnswer!,
        userAnswer: returnedFeedback.answer,
      });
      if (returnedFeedback.correctAnswer === returnedFeedback.answer) {
        new Audio(SussesSound).play();
      } else {
        new Audio(WrongSound).play();
      }
      const timer = setTimeout(() => {
        setQuestions((prev) => {
          const updatedQuestions = [...prev];
          const currentQuestionIndex = updatedQuestions.findIndex(
            (q) => q.id === currentQuestion?.id
          );
          if (currentQuestionIndex !== -1) {
            updatedQuestions[currentQuestionIndex].isAnswer = true;
          }
          return updatedQuestions;
        });
        setFeedback({ correctAnswer: "", userAnswer: "" });
      }, 2500);
      return () => {
        if (timer) clearTimeout(timer);
      };
    },

    [currentQuestion, questions, user, isLoading]
  );

  const questionFormat = useMemo(() => {
    const questionValue = currentQuestion?.value,
      lastChar = questionValue?.substr(-1),
      len = questionValue?.length || 0;
    return isNaN(Number(lastChar)) ? (
      questionValue
    ) : (
      <>
        {questionValue?.substring(0, len - 1)}
        <span>{lastChar}</span>
      </>
    );
  }, [currentQuestion]);

  const questionReferenceIndex = useMemo(() => {
    return currentQuestion?.value?.substr(-1) || "";
  }, [currentQuestion]);

  useEffect(() => {
    getRandomQuestion();
    setLoading(false);
  }, [questions]);

  useEffect(() => {
    if (user?.id) updateStartAt(user?.id);
  }, []);
  return (
    <section className="game-container">
      {isLoading && <Loader message={""} />}

      {!isGameEnd && currentQuestion && (
        <>
          <header>
            <div>
              <p className="title">TIME</p>
              <p className="value ">{timerValue}</p>
            </div>
            <div>
              <p className="title">SCORE</p>
              <p className="value">{score}</p>
            </div>
          </header>
          <article className="question-container">
            <h2>{questionFormat}</h2>

            <section className="answers-container">
              {currentQuestion?.answers.map((answer) => (
                <p
                  key={answer}
                  onClick={() => {
                    answerAction(answer);
                  }}
                >
                  {answer}
                  {feedback.correctAnswer === answer ? (
                    <img src={CorrectAnswerImage} />
                  ) : feedback.userAnswer === answer ? (
                    <img src={WrongAnswerImage} />
                  ) : (
                    ""
                  )}
                </p>
              ))}
            </section>
          </article>
        </>
      )}

      {isGameEnd && <GameResult />}
      {!isGameEnd && currentQuestion && (
        <p className="question-reference">
          {QUESTION_REFERENCES[`${questionReferenceIndex}`]}
        </p>
      )}
    </section>
  );
}
