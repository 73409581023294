import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAllUsers } from "../utils/firebase.actions";
import { User } from "../utils/types";
import Nav from "../components/Nav";
import { getDurationBetweenDates } from "../utils";
import Loader from "../components/Loader";
import UserAnswers from "../components/UserAnswers";

export default function Dashboard() {
  const [users, setUsers] = useState<User[] | null>(null);
  const [modalUser, setModalUser] = useState<User | null>(null);
  const [modalViability, setModalViability] = useState<boolean>(false);
  const loadUsers = useCallback(async () => {
    const users = await getAllUsers();
    console.log(users);
    setUsers(
      users.sort((a, b) => {
        return (
          new Date(a.create_at).getTime() - new Date(b.create_at).getTime()
        );
      })
    );
  }, []);
  useEffect(() => {
    loadUsers();
  }, []);

  const userData = useMemo(() => {
    return users?.map((user) => {
      const duration = getDurationBetweenDates(
        user?.start_at,
        user?.lastAnswer_at
      );
      return (
        <tr key={user.id}>
          <td>{user.name}</td>
          <td>{user.score}</td>
          <td>
            {duration.minutes?.toString().padStart(2, "0")}:
            {duration.seconds?.toString().padStart(2, "0")}
          </td>
          <td>
            <i
              onClick={() => {
                setModalUser(user);
                setModalViability(true);
              }}
              className="fa-solid fa-eye"
            ></i>
          </td>
        </tr>
      );
    });
  }, [users]);
  return (
    <>
      <Nav />
      <article className="dashboard-container">
        <h2>Dashboard</h2>
        {!users ? <Loader message={""} /> : ""}
        {users && !users?.length && (
          <p className="empty-message"> No Data To Show</p>
        )}
        {!!users?.length && (
          <table>
            <thead>
              <tr>
                <th>User Name</th>
                <th>Score</th>
                <th>Time</th>
                <th>Answers</th>
              </tr>
            </thead>
            <tbody>{userData}</tbody>
          </table>
        )}
        <section
          onClick={() => {
            setModalViability(false);
          }}
          className={`modal modal-wrapper ${modalViability ? "visible" : ""}`}
        >
          <UserAnswers
            closeModal={() => {
              setModalViability(false);
            }}
            user={modalUser}
          />
        </section>
      </article>
    </>
  );
}
